<template>
  <div>
    <div
      class="d-flex justify-center"
      :class="
        $vuetify.breakpoint.mobile
          ? 'flex-column align-center'
          : 'flex-row py-6'
      "
    >
      <div style="width: 100%; max-width: 580px">
        <v-carousel v-if="gallery.length > 1" v-model="model">
          <v-carousel-item
            v-for="(item, i) in gallery"
            :key="i"
            :src="`https://mybabybook-uploads-prod.s3.amazonaws.com/private/${userId}/images/large/${item}`"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
        <img
          v-else
          style="width: 100% !important; max-width: 750px"
          :src="src"
        />
      </div>
      <div class="px-6 pt-3 pb-10">
        <h1>{{ title }}</h1>
        <div class="pt-2">{{ date }}</div>
        <p class="py-2">{{ notes }}</p>
        <div v-for="(field, i) in customFields" :key="i" class="mb-3">
          <h3>{{ field.question }}</h3>
          <div class="py-2">{{ field.answer }}</div>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="grey lighten-5">
      <div class="text-center py-10 px-4">
        <div>
          <img
            width="60"
            style="margin: 0 auto"
            src="@/assets/images/appstore.png"
          />
        </div>
        <strong>My Baby Book</strong> é um aplicativo de livro de recordações do
        bebê para smartphones Android e iOS.
      </div>
      <h3 class="text-center">Baixe agora nas lojas</h3>
      <div class="d-flex justify-center pt-5 pb-10">
        <div class="app-store pr-2">
          <a
            href="https://apps.apple.com/us/app/mybabybook-photos-and-memories/id1575310857#?platform=iphone"
          >
            <img
              alt="link apple app store"
              src="@/assets/images/app-store.png"
              height="40px"
            />
          </a>
        </div>
        <div class="play-store pl-2">
          <a
            href="https://play.google.com/store/apps/details?id=com.mybabybookapp.mybabybook_app"
          >
            <img
              alt="link google play store"
              src="@/assets/images/google-play.png"
              height="40px"
            />
          </a>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="d-flex flex-wrap justify-center py-4">
      <a class="px-2 py-1" href="https://mybabybookapp.com/about">Sobre</a>
      <a class="px-2 py-1" href="https://mybabybookapp.com/">Website</a>
      <a class="px-2 py-1" href="https://mybabybookapp.com/terms"
        >Termos de Uso</a
      >
      <a class="px-2 py-1" href="https://mybabybookapp.com/privacy"
        >Política de Privacidade</a
      >
      <a class="px-2 py-1" href="https://mybabybookapp.com/contact">Contato</a>
    </div>
    <div class="text-center pb-4">
      <div class="caption">
        © My Baby Book App. Todos os direitos reservados
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "public-memory",
  data: () => ({
    model: 0,
    src: "",
    title: "",
    notes: "",
    date: "",
    gallery: [],
    customFields: [],
    userId: "",
  }),
  mounted() {
    const { id_book, id_memory } = this.$route.params;
    this.$request
      .post("user/memorys/getMemoryPublic", {
        BookId: id_book,
        MemoryId: id_memory,
      })
      .then((resp) => {
        const data = resp.data;
        this.src = data.ImageLargeURL;
        this.title = data.Title;
        this.date = new Date(data.CreatedAt).toLocaleDateString();
        this.notes = data.Notes;
        this.customFields = data.CustomFields;
        this.gallery = data.Gallery != null ? data.Gallery : [];
        this.userId = data.UserId;

        // Document Config SEO
        document.title = data.Title;

        const ogUrl = document.querySelector("meta[property='og:url']");
        ogUrl.setAttribute("content", window.location.href);

        const ogTitle = document.querySelector("meta[property='og:title']");
        ogTitle.setAttribute("content", data.Title);

        const ogDescription = document.querySelector(
          "meta[property='og:description']"
        );
        ogDescription.setAttribute("content", data.Notes);

        const ogImage = document.querySelector("meta[property='og:image']");
        ogImage.setAttribute("content", data.ImageLargeURL);
      });
  },
};
</script>