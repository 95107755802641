<template>
  <v-app-bar app elevation="0">
    <!-- ICON ARROW LEFT (BACK ROUTE) -->
    <v-btn
      v-if="backRoute"
      icon
      class="mr-3"
      @click="$router.push(backRoutepath)"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <!-- TITLE -->
    <v-toolbar-title v-if="namePage">
      {{ namePage }}
    </v-toolbar-title>

    <!-- LOGO -->
    <h2 v-if="logo" class="name-logo">
      <span class="my">my</span>
      <span class="baby">baby</span>
      <span class="book">book</span>
    </h2>

    <v-spacer></v-spacer>

    <MenuUserLogged v-if="menuUserLogged" />
  </v-app-bar>
</template>
<script>
import MenuUserLogged from "./menu_user_logged.vue";
export default {
  components: { MenuUserLogged },
  name: "app-bar",
  props: {
    backRoute: {
      type: Boolean,
      default: true,
    },
    backRoutepath: {
      type: String,
      default: "/",
    },
    logo: {
      type: Boolean,
      default: false,
    },
    namePage: {
      type: String,
      default: "",
    },
    menuUserLogged: {
      type: Boolean,
      default: false,
    },
  },
};
</script>