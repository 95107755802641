<template>
  <v-app>
    <AppBar :logo="true" :backRoute="false" :menuUserLogged="true" />
    <v-main>
      <v-container>
        <v-row>
          <v-col v-for="(item, i) in items" :key="i" cols="6">
            <BookCard :book="item" />
          </v-col>
        </v-row>
      </v-container>
      <v-fab-transition>
        <v-btn color="primary" fab large right class="v-btn--fab">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-main>
  </v-app>
</template>
<script>
import AppBar from "@/components/appbar/appbar.vue";
import BookCard from "@/components/books/book_card.vue";
export default {
  components: { AppBar, BookCard },
  data: () => ({
    items: [],
  }),
  mounted() {
    // List Books
    var { sub } = JSON.parse(localStorage.getItem("getUser"));
    this.$request
      .post("user/books/listBooks", {
        UserId: sub,
      })
      .then((response) => {
        this.items = response.data.Items;
      });
  },
};
</script>
<style>
.v-btn--fab {
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 0 16px 16px 16px;
}
</style>