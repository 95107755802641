<template>
  <v-card
    class="pa-4 d-flex flex-column"
    @click="bookRead(book.BookId)"
    style="border-radius: 16px"
    elevation="10"
    :height="height"
    :color="book.BookTheme.ColorsTheme.Surface"
  >
    <div
      :style="{
        backgroundImage: `url(${book.BookTheme.Cover})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        borderRadius: '16px',
        flexBasis: '80%',
      }"
      class="d-flex align-center justify-center"
    >
      <div
        :style="{
          backgroundImage: `url(${book.ImageMediumURL})`,
          width: $vuetify.breakpoint.mobile ? '100px' : '200px',
          height: $vuetify.breakpoint.mobile ? '100px' : '200px',
          borderRadius: '100px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }"
      ></div>
    </div>
    <div
      class="text-center py-6 d-flex justify-center align-center"
      style="flex-basis: 20%"
      :style="{
        fontSize: $vuetify.breakpoint.mobile ? '16px' : '24px',
        color: `${book.BookTheme.ColorsTheme.OnSurface}`,
      }"
    >
      {{ book.Title }}
    </div>
  </v-card>
</template>
<script>
export default {
  name: "component-book-card",
  props: ["book"],
  data: () => ({
    height: 0,
  }),
  mounted() {
    var divider = this.$vuetify.breakpoint.mobile ? 2 : 3;
    this.height = (window.screen.height - 64 - 24) / divider;
  },
  methods: {
    bookRead(bookId) {
      this.$router.push(`${bookId}`);
    },
  },
};
</script>