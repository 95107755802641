import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style.css'
Vue.config.productionTip = false

/// [ Plugins ]
import vuetify from './plugins/vuetify';
import axios from './plugins/axios';
import request from './plugins/request';

Vue.use(axios)
Vue.use(request)

new Vue({
  router,
  store,
  vuetify,
  mounted() {
    const auth = localStorage.getItem("Auth");
    if (auth === 'true') {
      const { IdToken, TokenType } = JSON.parse(localStorage.getItem('AuthenticationResult'));
      this.$axios.defaults.headers.common['Authorization'] = `${TokenType} ${IdToken}`;
    }
  },
  render: h => h(App)
}).$mount('#app')
