<template>
  <v-app>
    <v-app-bar app elevation="0">
      <v-spacer></v-spacer>
      <h2 class="name-logo">
        <span class="my">my</span>
        <span class="baby">baby</span>
        <span class="book">book</span>
      </h2>
      <v-spacer></v-spacer>
      <!-- <v-btn
        class="pa-0"
        href="https://mybabybookapp.com/"
        target="_blank"
        text
      >
        <span class="mr-2">Download App</span>
      </v-btn> -->
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "public",
};
</script>