<template>
  <v-menu offset-y transition="slide-y-transition" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon dark v-bind="attrs" v-on="on">
        <v-icon color="primary">mdi-account-circle</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="mb-1">{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-chip small>Plano free</v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item-group nav v-model="settings">
        <template v-for="(item, i) in items">
          <v-list-item :key="i" @click="routerPush(item.action)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
import logout from "@/utils/logout";
export default {
  data: () => ({
    settings: [],
    user: JSON.parse(localStorage.getItem("getUser")),
  }),
  computed: {
    items() {
      return [
        {
          text: "Minha conta",
          icon: "mdi-account",
          action: "profile",
        },
        {
          text: "Assinatura",
          icon: "mdi-credit-card-outline",
          action: "purchases",
        },
        {
          text: "Alterar senha",
          icon: "mdi-lock",
          action: "changePassword",
        },
        {
          text: "Sair",
          icon: "mdi-logout",
          action: "logout",
        },
      ];
    },
  },
  methods: {
    routerPush(routeName) {
      if (routeName != "logout") {
        this.$router.push({ name: routeName });
      } else {
        logout();
      }
    },
  },
};
</script>