import store from '@/store'
export default {
  install(Vue) {
    Vue.prototype.$request = {
      get: async function (endpoint) {
        store.commit("progress", true);
        return new Promise((resolve, reject) => {
          Vue.prototype.$axios.get(endpoint)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            })
        });
      },

      post: async function (endpoint, params) {
        store.commit("progress", true);
        return new Promise((resolve, reject) => {
          Vue.prototype.$axios.post(endpoint,
            params)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            })
        });
      },
    }
  }
}
