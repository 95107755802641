import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: '',
    progress: false,
  },
  mutations: {
    // Feedback Snackbar
    snackbar(state, data) {
      state.snackbar = data
    },
    // Feedback Progress
    progress(state, data) {
      state.progress = data
    },
  },
  actions: {
  },
  modules: {
  }
});
