import axios from 'axios'
import store from '@/store'
import logout from '../utils/logout';
export default {
  install(Vue) {

    /// [ Config ]
    Vue.prototype.$axios = axios.create({
      baseURL: "https://api.mybabybookapp.com/",
      // headers: {}
    });

    /// [ Interceptors ]
    Vue.prototype.$axios.interceptors.response.use(function (response) {
      store.commit('progress', false);
      return response;
    }, function (error) {

      // Error Unauthorized 401
      if (error.response.status === 401) {
        logout();
      }

      // Error 400
      if (error.response.status === 400) {
        store.commit('snackbar', {
          msg: error.response.data.message,
          color: 'warning'
        });
      }

      store.commit('progress', false);
      return Promise.reject(error);
    });
  }
}