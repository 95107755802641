var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4 d-flex flex-column",staticStyle:{"border-radius":"16px"},attrs:{"elevation":"10","height":_vm.height,"color":_vm.book.BookTheme.ColorsTheme.Surface},on:{"click":function($event){return _vm.bookRead(_vm.book.BookId)}}},[_c('div',{staticClass:"d-flex align-center justify-center",style:({
      backgroundImage: ("url(" + (_vm.book.BookTheme.Cover) + ")"),
      backgroundRepeat: 'repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      borderRadius: '16px',
      flexBasis: '80%',
    })},[_c('div',{style:({
        backgroundImage: ("url(" + (_vm.book.ImageMediumURL) + ")"),
        width: _vm.$vuetify.breakpoint.mobile ? '100px' : '200px',
        height: _vm.$vuetify.breakpoint.mobile ? '100px' : '200px',
        borderRadius: '100px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      })})]),_c('div',{staticClass:"text-center py-6 d-flex justify-center align-center",staticStyle:{"flex-basis":"20%"},style:({
      fontSize: _vm.$vuetify.breakpoint.mobile ? '16px' : '24px',
      color: ("" + (_vm.book.BookTheme.ColorsTheme.OnSurface)),
    })},[_vm._v(" "+_vm._s(_vm.book.Title)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }