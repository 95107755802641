<template>
  <v-app>
    <AppBar :backRoutepath="'list'" />
    <v-main>
      <v-container>
        <v-row>
          <v-col
            v-for="(item, i) in items"
            :key="i"
            :cols="$vuetify.breakpoint.mobile ? 6 : 4"
          >
            <!-- CARD BOOK -->
            <v-card class="pa-3">
              <v-img
                :src="item.ImageMediumURL"
                :lazy-src="item.ImageMediumURL"
                aspect-ratio="9/16"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <div class="text-center py-4">
                {{ item.Title }}
              </div>
            </v-card>
            <!-- / CARD BOOK -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AppBar from "@/components/appbar/appbar.vue";
export default {
  components: { AppBar },
  name: "book-read",
  data: () => ({
    items: [],
  }),
  mounted() {
    // List Books
    var { sub } = JSON.parse(localStorage.getItem("getUser"));
    this.$request
      .post("user/memorys/listMemorysBook", {
        UserId: sub,
        BookId: this.$route.params.book_id,
      })
      .then((response) => {
        this.items = response.data.Items;
      });
  },
};
</script>