export default {
  // Main Colors
  mPurple: {
    base: "#C96FD8",
    lighten5: "#efd3f3",
    lighten4: "#e7bfee",
    lighten3: "#e0abe8",
    lighten2: "#d897e3",
    lighten1: "#d183dd",
    darken1: "#c15bd3",
    darken2: "#ba47cd",
    darken3: "#b135c5",
    darken4: "#9f30b1",
  },
  mYellow: {
    base: "#FFC564",
    lighten5: "#fff5e3",
    lighten4: "#ffebca",
    lighten3: "#ffe2b0",
    lighten2: "#ffd897",
    lighten1: "#ffcf7d",
    darken1: "#ffbb4a",
    darken2: "#ffb231",
    darken3: "#ffa817",
    darken4: "#fd9e00",
  },
  // Complementary Colors
  cBlue: {
    base: "#94CFF4",
    lighten5: "#f0f8fe",
    lighten4: "#d9eefb",
    lighten3: "#c2e4f9",
    lighten2: "#abd9f6",
    lighten1: "#94cff4",
    darken1: "#7dc5f2",
    darken2: "#66baef",
    darken3: "#4fb0ed",
    darken4: "#38a6ea",
  },
  cRed: {
    base: "#F8B7A5",
    lighten5: "#fef0ec",
    lighten4: "#fcddd4",
    lighten3: "#facabd",
    lighten2: "#f8b7a5",
    lighten1: "#f8b7a5",
    darken1: "#f6a48d",
    darken2: "#f49176",
    darken3: "#f27e5e",
    darken4: "#f16b46",
  },
  // Colors Default:
  primary: '#C96FD8',
}