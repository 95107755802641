import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 
  // PUBLIC
  // 
  {
    path: '/public',
    name: 'public',
    component: () => import(/* webpackChunkName: "public" */ '../views/public'),
    children: [
      // Memory
      {
        path: 'memory/:id_book/:id_memory',
        name: 'publicMemory',
        component: () => import(/* webpackChunkName: "publicMemory" */ '../views/public/memory'),
      }
    ]
  },
  // 
  // PRE AUTHENTICATION
  // 
  {
    path: '/auth',
    name: 'auth',
    redirect: { name: 'sign_in' },
    component: () => import('@/views/router_view'),
    children: [
      // Sign_In
      {
        path: 'sign-in',
        name: 'sign_in',
        component: () => import(/* webpackChunkName: "sign_in" */ '../views/auth/sign_in'),
      },
      // Sign_Up
    ]
  },
  // 
  // POS AUTHENTICATION
  //
  {
    path: '/',
    meta: { requiresAuth: true },
    redirect: { name: 'books_list' },
    component: () => import('@/views/home_view'),
    children: [
      // BOOKS
      {
        path: 'books',
        name: 'books',
        redirect: { name: 'books_list' },
        component: () => import('@/views/router_view'),
        children: [
          // Books List
          {
            path: 'list',
            name: 'books_list',
            component: () => import(/* webpackChunkName: "books_list" */ '../views/books/books_list'),
          },
          // Book Read
          {
            path: ':book_id',
            name: 'book_read',
            component: () => import(/* webpackChunkName: "book_read" */ '../views/books/book_read'),
          }
        ],
      },
      // MEMORYS
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const auth = localStorage.getItem("Auth");
    if (auth === "true") {
      next();
    } else {
      next({
        path: `/auth`,
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next();
  }
})

export default router
