<template>
  <v-app>
    <v-container fluid class="fill-height cBlue lighten-3">
      <v-row justify="center" class="align-center">
        <v-col cols="12" sm="8" md="4">
          <div class="d-flex justify-center">
            <v-img max-width="112" src="../../assets/images/icon.png"></v-img>
          </div>
          <v-card
            class="transparent mx-auto"
            style="max-width: 400px"
            color=""
            elevation="0"
          >
            <v-form>
              <v-card-text class="px-10">
                <v-text-field
                  label="Login"
                  name="login"
                  type="text"
                  v-model="form.username"
                  solo
                  clearable
                  tabindex="1"
                ></v-text-field>
                <v-text-field
                  id="password"
                  label="Senha"
                  name="password"
                  v-model="form.password"
                  solo
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  tabindex="2"
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="pb-4 px-10">
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loading"
                  type="submit"
                  color="primary"
                  v-on:click.prevent="login"
                  tabindex="3"
                  >Entrar</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import Vue from "vue";
export default {
  name: "auth_sign_in",
  data: () => {
    return {
      show1: false,
      loading: false,
      form: {
        username: "prints@mybabybookapp.com",
        password: "02030812*!",
      },
    };
  },
  methods: {
    login() {
      this.loading = true;
      const body = {
        username: this.form.username,
        password: this.form.password,
      };
      this.$request
        .post("auth/initiateAuth", body)
        .then(async (response) => {
          const data = response.data.AuthenticationResult;
          // Store
          localStorage.setItem("AuthenticationResult", JSON.stringify(data));
          localStorage.setItem("Auth", "true");
          // Setup Axios Bearer Token
          Vue.prototype.$axios.defaults.headers.common[
            "Authorization"
          ] = `${data.TokenType} ${data.IdToken}`;

          return data.AccessToken;
        })
        .then(async (accessToken) => {
          // Get User Data
          const response = await this.$request.post("auth/getUser", {
            accessToken,
          });
          localStorage.setItem("getUser", JSON.stringify(response.data));
        })
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({ name: "books_list" });
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>